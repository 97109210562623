import { enableProdMode } from '@angular/core';
import { environment as env, environment } from './environments/environment';
import { addLinkTagInHead } from '@nats/shared/functions';

if (environment.production) {
	enableProdMode();

	window.console.log = () => {
		// this will prevent the console from logging anything on production
	};
}

async function loadConfigData() {
	const config = await fetch(env.resourceConfigPath).then(res => res.json());
	const bootstrap = import('./app/bootstrap-app').then(({ bootstrap }) => bootstrap);

	Promise.all([config, bootstrap]).then(([config, bootstrap]) => {
		if (config) {
			addLinkTagInHead(config.areaUrl, 'preconnect');
			bootstrap(config);
		} else {
			alert("Couldn't load config file!");
		}
	});
}

loadConfigData();
